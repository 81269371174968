import React, { useState, useLayoutEffect } from "react";
import ReactMarkdown from "react-markdown";

import Info from "@templates/Info";
import ContentBox from "@atoms/ContentBox";
import Icon from "@atoms/Icon";
import Text from "@atoms/Text";

import { privacyPolicy } from "@content/privacyPolicy";
import LockSvg from "@images/icons/lock.svg";

const PrivacyPage = () => {
  const [startPage, setStartPage] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setStartPage(true), 500);
  }, [setStartPage]);

  return (
    <Info active={startPage}>
      <ContentBox>
        <h2>
          <Icon src={LockSvg} /> Privacy Policy
        </h2>
      </ContentBox>
      <ContentBox>
        <Text>
          <ReactMarkdown children={privacyPolicy.content} />
        </Text>
      </ContentBox>
    </Info>
  );
};

export default PrivacyPage;
