export const privacyPolicy = {
  content: `
### General Data Protection Regulation (GDPR)
1. Personal data provided during contact with the Website are processed by kobylarz.co from Bojanow, ul. Tarnobrzeska 20, 37-433 Bojanow, NIP: 8652522102, based on the provisions of the Personal Data Protection Act of 29 August, (Journal of Laws 1997 No. 133, item 883, as amended) and the Act on electronic services of July 18, 2002 (Journal of Journal of Laws of 2002, No. 144, item 1204, as amended).
2. Although providing data is voluntary, refusal to provide data may result in non-performance of services.
3. The sole Administrator of the data entered in the forms on the Website is the Website Owner.
4. The User has the right to view, change or delete their personal data at any time, except for the temporary unavailability of the Website arising from work carried out related to the maintenance of the IT infrastructure or temporary problems related to its functioning independent of the Website.
5. Users' data are not disclosed to other entities, with the exception of public authorities authorized under separate legal provisions.
### Data security
1. The website service takes measures to protect personal data against unauthorized access, in accordance with the Regulation of the Minister of Interior and Administration of 29 April 2004 on the documentation of personal data processing as well as technical and organizational conditions to be met by devices and IT systems used for processing personal data (Journal of Laws of 2004 No. 100, item 1024).
Cookies and server logs
1. To facilitate the use of the Website and monitor its use, the management mechanism uses so-called "cookies technology" - information saved by the Website server, the hosting operator of Netlify, operating at www.netlify.com and on the User's computer or mobile device.
2. The website uses two types of Cookies:
- Session cookies -- temporary files stored on the User's device until they log out, leave the website or turn off the web browser;
- Permanent cookies -- stored on the User's device for the time specified in their parameters or until their removal by the User.
3. Cookies technology is not used to obtain any data about Website visitors.
4. The user may at any time disable the option of accepting cookies in the settings of their web browser. However, this may cause irregularities in the operation of the Website. Below are links to information on how to disable cookies in the most popular web browsers:
- Chrome
- Firefox
- Safari
- Microsoft Edge
5. Cookies are used to:
- Create statistics that help to understand how to use the Website in order to improve its content;
- Maintain the User's session, thanks to which it is not necessary to always enter the password and login.
- Personalization of advertising materials in the Google network after prior definition of the User's profile and forwarding them to the Server partners.
6. Collect server logs containing, among others, User's IP address, time of inquiry, first line of http request, http response code, number of bytes sent by the server, information about the User's browser, information about errors that occurred during the implementation of the HTTP transaction and information about the type of devices are stored indefinitely.
7. The administrator may use the above data to generate statistics helpful in administering the Website and to improve the quality of services offered. These statistics are aggregate and do not contain data identifying visitors to the Website. Data are not disclosed to other persons or entities.`,
};
